import defaultCurrencies from '../data/currencies';
import { GoogleSpreadsheet } from 'google-spreadsheet';
import axios from 'axios';

const { fetch } = window;
const USD_ISO_CODE = 'JPY';
const NO_CURRENCY_SYMBOL = '';
const EXCHANGE_RATES_BASE_URL = 'https://api.exchangeratesapi.io/';
const LATEST_ENDPOINT = 'latest';
const SOURCE_INFO_URL = 'https://exchangeratesapi.io/';
const SOURCE_NAME = 'exchangeratesapi.io';
const doc = new GoogleSpreadsheet('1mh6NzQVmnh_G2xHfUQpr2Zir_LS9N9iHUWPbIX5Eaz8');


class Rates {
  constructor() {
    this.source = {
      url: SOURCE_INFO_URL,
      name: SOURCE_NAME,
    };
    this.endpoint = LATEST_ENDPOINT;
    this.base = USD_ISO_CODE;
    this.date = null;
    this.rates = {};
    this.isLoaded = false;
    this.error = null;
  }

  getDate() {
    return this.date;
  }

  convert(currencyCodeFrom = USD_ISO_CODE, currencyCodeTo = USD_ISO_CODE, amount = 0) {
    if (!this.isLoaded || !this.rates[currencyCodeFrom] || !this.rates[currencyCodeTo]) {
      return false;
    }
    const baseAmount = this.convertToBase(currencyCodeFrom, amount);
    const finalAmount = this.convertFromBase(currencyCodeTo, baseAmount);
    return finalAmount;
  }

  convertToBase(currencyCodeFrom = USD_ISO_CODE, amount = 0) {
    if (!this.rates[currencyCodeFrom]) { return false; }
    return Number(amount) / this.rates[currencyCodeFrom];
  }

  convertFromBase(currencyCodeTo = USD_ISO_CODE, amount = 0) {
    if (!this.rates[currencyCodeTo]) { return false; }
    return Number(amount) * this.rates[currencyCodeTo];
  }

  getLoadParams(base = this.base) {
    return `base=${base}`;
  }

  load(base = this.base, reload = false) {
    if (this.isLoaded && !reload) { return Promise.resolve(); }
    this.isLoaded = false;
     async function fetchData() {
      await doc.useServiceAccountAuth({
        client_email: 'gsheet@tubercollector.iam.gserviceaccount.com',
        private_key: '-----BEGIN PRIVATE KEY-----\nMIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQDJfA9XkLpZcjPo\nT2BKsRY2OQJI3vUXdhX+BqwDvQtys1k0ADKZcaZcouRm1s4KD8svrDHKUwmrDUaJ\n+rB/QpyBf4U+jkOpRstPx7Jd3M1LQsFoPwYFLUhHVUTkjKvcGmcln8Onz5J/IIyK\nNYpcQTLataFykQkV1LeLXddhS5HvsCyPIRSWI8qen7y65RUdeLnuIo1Wwmn8VlyV\nFu+9TrQjzoh/6b/ZQkfgjm+kghZd8Hzm8O18hPUpbvnO7DJUfstei8aX/48l2Q6A\nLwZ+ekFsEGQpmFNV7S/UqDgXH+25ATKd2DVnsES/Z3pkLxcLKKZsoDP/SzOLQYli\nI/21vwaBAgMBAAECggEAC5ouIHzcLD8qK8oZWYe7x5TOE7/qBWc2gj2kXWU/EeRv\nwjGyODmOCsbTB/R94iCxcr3z0oqoU34k1hh2Xiep7z+WfskOrqJd3grZ87N9q2Qt\nUrlRWY43W6s2d8I9ecPX9XfPGpxWe2VWjnVhkakr+mHr6XbeWvBYPulhGBBT16VX\nWhJX1lUTjjcB+CB8+SvcarrYwkWZswPcDYsxjFAKSzRm2E24SZ3EoaPTwBIp7Dd5\nzW4h2eFbP4wXRMJfNjHva0kvo1Mr1aymzaObyguXdNRINBfw8KU33JOCCQsaCXam\nj004QbMYfqxsD6wP1IFOahVM/S6EivGcjDm0h07xyQKBgQD1FFX/4N/q8FS8Gon4\nVxIdtVgoCZuhTr+T5zOkVeCT6Tp/JrNut7/pGpP+2hKjQvRWWRyT1wcaKDdHxn80\nTDcufOTLANOYLQf/9wfOWIOPb5+P1pcDA8YvSfrGS2PLTwzWxk58FTJaatsqaqow\nJuvq+QV6YOj/p1PEYPqQ/0yzCQKBgQDSdm4el3DOEQFYyRf1KDrVd94YEDyncrrX\niqgLCXBoeSSBGMx0XX8y94Af/U8M66BPOjO9oCcLLrYxwk8YmiGrIPOzWq0RpmPL\nHRtaZPkpopPQHlJU5wFLQjr3O1sxbSfNJ5tBWE0/Zvq27K553AugFEpYLNpoXPgw\n9Sv8MwS9uQKBgQCC94EVokhGtnOxqcT9LxqBQb2Ad9HxG7IJCc5xDWtb35DhDf9p\nNUya9xcLY+H92PPViP1ai9hBxCf2dT7Y5bm/YAKr8R8h/T5/LLXBMU/swMvWA+jj\nNYsdDj2y5erIZNAJniOm73TfGKdSPXoBDWuZP8YLpeodZH/BO8nq3mNQcQKBgQCL\n4vu+iZoI3XSvtrmd0WeJi2qrQ9+MzM0zho27CEMWra59wCXlEmABWFBo8s0gIV0k\nS+MoHtLUs++VkzlPeOL4A6b3h0WRfa7GyknsA0E5Uz48Dmta01Ag3Zyjx2gNqifS\nTIj9Hdv6UFBEVXyCsqdndxUTFnz08RZmNA9QSfjUwQKBgQCY3IrsKNTs0GWOoWc6\n4ZcGrD9X7vJE9zQwPKAL+gD4xAAnBPsZzTcj8I5i9vEmtkG5vqTV+wmVTT2VUHAW\ntN0Z9QBj1/Hi3mQh3MEm/wh90xckIq9KdxI+wEqNytVukJ/BqjiJT7O9QFdT6paO\npJCFHPerwYGFPLST91BqXegRrw==\n-----END PRIVATE KEY-----\n',
      });
      await doc.loadInfo();
      const sheet = doc.sheetsByIndex[0];
      await sheet.loadCells();
      const cell = await sheet.getCell(0,1);
      const yenPrice = await axios.get('https://api.currencyapi.com/v3/latest?apikey=AWc0GtEmk3YuTBDtjvhxB706Xr1xvqZffkrA7NXK&currencies=JPY');
      return cell.value * yenPrice.data.data.JPY.value;
     }
      const date = new Date().toLocaleDateString();

	  return fetchData().then((cell) => {
          this.error = null;
          this.base = 'JPY';
          this.date = date;
          this.rates = {JPY: cell, STcoin: 1};

	  },
        (error) => {
          this.error = error;
        },
	)
      .finally(() => { this.isLoaded = true; });
  }

  getCurrencies() {
    const currencies = [...defaultCurrencies];
    if (!this.isLoaded) {
      return currencies;
    }
    // Remove any currencies from the list if we don't have rates for them
    currencies.filter((currency) => this.rates[currency.code]);
    // Add any additional currencies if we have rates
    Object.keys(this.rates).forEach((code) => {
      const foundCurrency = currencies.find((currency) => currency.code === code);
      if (!foundCurrency) {
        currencies.push({ code, symbol: NO_CURRENCY_SYMBOL });
      }
    });
    // Alphabetize
    return currencies.sort((curr1, curr2) => ((curr1.code < curr2.code) ? -1 : 0));
  }

  static validator() {
    // TODO: Validate an instantiated object via PropTypes.objectOf
  }
}

export default Rates;
