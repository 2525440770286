import React from 'react';

import './App.css';
import CurrencyConverter from './CurrencyConverter';
import cyberwallet from './cyberwallet.svg';
import tokenum from './tokenum.svg';
function App() {
  return (
    <main className="App">
      <CurrencyConverter />
      <footer className="App-footer">
        <span className='App-credits'>
          <a href="https://cyberwallet.ai/" className="App-author">
          <img src={cyberwallet}/>
          </a>
        </span>
        <span className='App-credits'>
          <a href="https://tokenum.net/ja" className="App-author">
          <img src={tokenum}/>
          </a>
        </span>
      </footer>
    </main>
  );
}

export default App;
