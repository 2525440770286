import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import CurrencyConverterForm from './CurrencyConverterForm';
import CurrencyHistory from './CurrencyHistory';
import CurrencyRatesStatus from './CurrencyRatesStatus';
import Rates from './services/Rates';
import './CurrencyConverter.css';
import {Helmet} from "react-helmet";

// Functional component style with Hooks

function CurrencyConverter() {
  const [rates] = useState(new Rates());
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [currency, setCurrency] = useState([]);

  useEffect(() => {
	  console.log("CurrencyConverter: useEffect");
    rates.load()
      .finally(() => {
        setError(rates.error);
        setIsLoaded(rates.isLoaded);
      });
  },[]);

  const onCurrencyChange = (curr) => setCurrency([...curr]);

  return (
    <article className="CurrencyConverter">
	<Helmet>
        <script async src="https://price-static.crypto.com/latest/public/static/widget/index.js"></script>
      </Helmet>
      <header className="CurrencyConverter-header">
        <h2>
          ST coin Currency Converter Powered by Tokenum
        </h2>
        <div className="CurrencyConverter-subtitle">
	  Convert STcoin to Japanese yen
        </div>
      </header>
      <CurrencyConverterForm rates={rates} onCurrencyChange={onCurrencyChange} />
	  <div>
	  <iframe src="https://widget.coinlib.io/widget?type=single_v2&theme=dark&coin_id=859&pref_coin_id=1505" width="250" height="196px" scrolling="auto" marginWidth="0" marginHeight="0" frameBorder="0" border="0"></iframe>
	  </div>
    </article>
  );
}

export default CurrencyConverter;
