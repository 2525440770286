import React, {useState} from 'react';
import PropTypes from 'prop-types';
import CurrencySelector from './input-components/CurrencySelector';
import AmountInput from './input-components/AmountInput';
import NameInput from './input-components/NameInput';
import Rates from './services/Rates';
import roundToCents from './roundToCents';
import './CurrencyConverterForm.css';
import emailjs from '@emailjs/browser';


const NOOP = () => {};
const EMPTY_AMOUNT = '';

// Class Component example

class CurrencyConverterForm extends React.Component {
  static getConversionIndex(i) {
    return (i === 0) ? 1 : 0;
  }

  constructor(props = {}) {
    super();
    const { rates, onCurrencyChange } = props;
    this.rates = rates;
    this.state = {
      currency: ['STcoin', 'JPY'],
      amounts: [EMPTY_AMOUNT, EMPTY_AMOUNT],
      name: 'yourname',
      amount: '0',
      buttonClicked: false,
    };
    emailjs.init({publicKey: "lI04n_KEjNM23WIl-",});
    this.onCurrencyChange = onCurrencyChange;
    // Trigger this on instantiation to send the currencies back to parent
    const { currency } = this.state;
    this.onCurrencyChange(currency);
  }

  handleCurrencyChange(currencyCode, i) {
    const { amounts, currency } = this.state;
    currency[i] = currencyCode;
    this.setState({ currency });
    if (amounts[i] !== EMPTY_AMOUNT) {
      this.handleAmountChange(amounts[0], 0);
    }
    this.onCurrencyChange(currency); // callback
  }

  handleAmountChange(amount, i) {
    const { currency } = this.state;
    const conversionIndex = CurrencyConverterForm.getConversionIndex(i);
    const amounts = [];
    amounts[i] = String(amount);
    const fromCurrency = currency[i];
    const toCurrency = currency[conversionIndex];
    const convertedAmount = this.rates.convert(fromCurrency, toCurrency, amount);
    amounts[conversionIndex] = String(roundToCents(convertedAmount));

    this.setState({ amounts });
    // console.log(this.state);
  }
  sendEmail() {
    var templateParams = {
    from_name: this.state.name,
    message: this.state.amount,
  };
    emailjs.send("service_d6dcdbd","template_zq3ap5t", templateParams, 'lI04n_KEjNM23WIl-')
    .then(function(response) {
	window.alert("submitted");
    }, function(error) {
       console.log('FAILED...', error);
    });
  }

  renderCurrencyColumn(currencies = [], i = 0) {
    const { currency, amounts } = this.state;
    const onCurrencyChanges = (currencyCode) => this.handleCurrencyChange(currencyCode, i);
    const onAmountChanges = (amount) => this.handleAmountChange(amount, i);
    return (
      <div>
        <CurrencySelector
          incomingCurrencyCode={currency[i]}
          onChange={onCurrencyChanges}
          currencies={currencies}
        />
        <AmountInput incomingAmount={amounts[i]} onChange={onAmountChanges} />
      </div>
    );
  }

  renderForm() {
    const { name, amount } = this.state;
    return (
      <div>
        <NameInput incomingAmount={name} onChange={(name) => this.setState({name : name})} />
        <AmountInput incomingAmount={amount} onChange={() => this.setState({amount : amount})} />
      </div>
    );
  }
  render() {
    const currencies = this.rates.getCurrencies();
    return (
      <section>
	{this.state.buttonClicked ?
      <div style={{display: 'flex', flexDirection:'column',alignItems:'center'}}>
        <form className="CurrencyConverterForm">
	<div>
	<div style={{display: 'flex',alignItems: 'flex-start',paddingLeft: '0.5em'}}>
	<a> name: </a>
	</div>
        <NameInput incomingAmount={this.state.name} onChange={(name) => this.setState({name : name})} />
	</div>
	<div>
	<div style={{display: 'flex',alignItems: 'flex-start',paddingLeft: '0.5em'}}>
	<a> amount: </a>
	</div>
        <AmountInput incomingAmount={this.state.amount} onChange={(amount) => this.setState({amount : amount})} />
	</div>
        </form>
	<div style={{marginTop: '1em'}}>
	<a> Destination address for ST coin transfer: </a>
	</div>
	<div style={{marginTop: '0.5em'}}>
	<a style={{fontSize: '0.8em'}}> 0x9a3594412Ab3bE772bA657CF3987650E53De2A3e </a>
	</div>
	<div>
	  <button
            type="button"
            className="CurrencyHistory-toggle-drawer-button"
            onClick={() => this.setState({buttonClicked : false})}
          >
            {'go back'}
          </button>
	  <button
            type="button"
            className="CurrencyHistory-toggle-drawer-button"
            onClick={() => this.sendEmail()}
          >
            {'submit'}
          </button>
	</div>
      </div>
		
	: 
	<>
        <form className="CurrencyConverterForm">
          {this.renderCurrencyColumn(currencies, 0)}
          <div>=</div>
          {this.renderCurrencyColumn(currencies, 1)}
        </form>
        <div>
	  <button
            type="button"
            className="CurrencyHistory-toggle-drawer-button"
            onClick={() => this.setState({ buttonClicked: true })}
          >
            {'Sell STcoin'}
          </button>
        </div>
	</>
	}
      </section>
    );
  }
}

CurrencyConverterForm.propTypes = {
  rates: PropTypes.objectOf(Rates.validator),
  onCurrencyChange: PropTypes.func,
};

CurrencyConverterForm.defaultProps = {
  rates: {},
  onCurrencyChange: NOOP,
};

export default CurrencyConverterForm;
